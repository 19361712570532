.paper {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    margin: 3px;
  };

.logo {
    margin-bottom: 20;
}

.form {
    width: "100%";
    margin-top: "8px";
  };

.submit {
    margin-top: "8px";
  };